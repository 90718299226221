import { Divider } from 'antd';
import React from 'react';
import moment from 'moment';
import { CopyButton } from '../../utils/common';
import { useSelector } from 'react-redux';

const Details = () => {
    const clientData = useSelector(state => state.account.clientData);

    const details = [
        { label: 'Email', value: clientData?.clientEmail || 'N/A' },
        { label: 'Account ID', value: clientData?.client9217261285 || 'N/A' },
        { label: 'Phone', value: clientData?.phoneNumber || 'N/A' },
        { label: 'Address', value: clientData?.address || 'N/A' },
        // { label: 'Address line 2', value: '120 Main Street' ||"-"},
        { label: 'City', value: clientData?.city || 'N/A' },
        // { label: 'State / Province / Region', value: 'Nottinghamshire' ||"-"},
        { label: 'ZIP / Postal code', value: clientData?.postCode || 'N/A' },
        { label: 'Country', value: clientData?.country || 'N/A' },
        {
            label: 'Registration Date',
            value: moment(clientData?.createdAt).format('D.MM.YY HH:mm') || 'N/A',
        },
        // { label: 'Password', value: '*********' || 'N/A' },
    ];
    return (
        <div className="bg-white">
            <h1 className="lg:hidden page-title p-5">Account Details</h1>
            <div className="text-sm font-normal px-6">
                <dl>
                    {details.slice(2).map((detail, index) => (
                        <div
                            key={index}
                            className={`flex justify-between py-[2px] ${index !== 0 ? 'mt-[8px]' : ''}`}>
                            <dt className="text-darkGray">{detail.label}:</dt>
                            <dd className="text-[#18181B] w-auto">
                                <div
                                    className={`${detail.label === 'Address' ? 'max-w-[150px]' : 'max-w-[250px]'} break-words`}>
                                    {detail.value} <CopyButton msg={detail.value} />
                                </div>
                            </dd>
                        </div>
                    ))}
                </dl>
            </div>
            {/* <Divider className="mt-2 mb-5" />
            <h1 className="font-semibold text-base mb-5 px-5">Enqura - Sumsub</h1>
            <div className="flex items-center gap-2 p-6">
                {Array.from({ length: 3 }).map((_, index) => (
                    <img
                        className="lg:w-[88px] w-[168px] h-[88px] rounded-xl object-cover"
                        src="https://via.placeholder.com/150"
                        alt="Avatar"
                        key={index}
                    />
                ))}
                <div className="lg:w-[88px] w-[168px] h-[88px] rounded-xl object-cover flex items-center justify-center bg-[#F4F4F5]">
                    +7
                </div>
            </div> */}
        </div>
    );
};

export default Details;
