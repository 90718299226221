import React, { useState } from 'react';
import { Form, DatePicker } from 'antd';
import { SearchOutlined, RedoOutlined, CalendarOutlined } from '@ant-design/icons';
import DrawerFilters from '../DrawerFilters';
import ThemeButton from '../common/ThemeButton';
import moment from 'moment';

const BalanceHistoryFilterDrawer = ({ open, setOpen, setFilters, setPage }) => {
    const [formValue, setFormValue] = useState({
        fromDate: '',
        toDate: '',
    });

    const [form] = Form.useForm();
    const { RangePicker } = DatePicker;

    const handleDateRange = (_, range) => {
        let [fromDate, toDate] = range;
        fromDate = moment.utc(fromDate, 'DD-MM-YYYY').startOf('day');
        toDate = moment.utc(toDate, 'DD-MM-YYYY').endOf('day');

        setFormValue(prevState => ({
            ...prevState,
            fromDate,
            toDate,
        }));
    };

    const handleCloseDrawer = () => {
        setOpen(false);
    };

    const handleSearch = () => {
        setPage(1);
        setFilters(formValue);
    };

    const handleResetValue = () => {
        setFormValue({
            fromDate: '',
            toDate: '',
        });
        form.resetFields();
        setOpen(false);
        setFilters([]);
        setPage(1);
    };

    function BalanceHistoryFilterButtons() {
        return (
            <div className="py-[8px] sm:py-[16px] grid grid-cols-[auto_1fr] lg:mt-6 sm:flex sm:justify-end sm:items-center gap-2">
                <ThemeButton
                    icon={<RedoOutlined />}
                    action={handleResetValue}
                    text="Reset"
                    autoWidth={true}
                    className="reset-filter-btn"
                />
                <ThemeButton
                    icon={<SearchOutlined />}
                    type="primary"
                    autoWidth={true}
                    action={() => {
                        handleSearch();
                        handleCloseDrawer();
                    }}
                    text="Search"
                />
            </div>
        );
    }
    return (
        <div>
            <DrawerFilters
                title="Filters"
                onClose={handleCloseDrawer}
                open={open}
                footer={<BalanceHistoryFilterButtons />}
                form={form}>
                <Form.Item label="Date" name="transactionType">
                    <RangePicker
                        className="w-full p-2"
                        suffixIcon={<CalendarOutlined className="text-lg" />}
                        format="DD-MM-YYYY"
                        onChange={handleDateRange}
                        popupClassName="dateRangePicker"
                    />
                </Form.Item>
            </DrawerFilters>
        </div>
    );
};

export default BalanceHistoryFilterDrawer;
