import React, { useEffect, useState } from 'react';
import Sidebar from '../components/layout/Sidebar';
import { Button, Divider, Tabs, Form, message, Dropdown, Menu, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Download, Edit } from '../utils/Images';
import { LeftOutlined, DownOutlined } from '@ant-design/icons';
import Details from '../components/accountDetailsTabs/Details';
import { useNavigate, useParams } from 'react-router-dom';
import { accountDetailsNav, feeProfileOptions, thresholdProfileOptions } from '../utils/constant';
import apiRequest from '../utils/api';
import {
    addAuditTrail,
    CopyButton,
    formatAmount,
    getCryptoBalances,
    useCommonFunctions,
} from '../utils/common';
import {
    clientFiatBalance,
    singleClientData,
    setClientFeeSetting,
    setBalancesData,
} from '../redux/actions/accountActions';
import DownloadCsvModal from '../components/modals/DownloadCsvModal';
import CommonModal from '../components/modals/CommonModal';
import dayjs from 'dayjs';
import BreadCrumHeader from '../components/common/BreadCrumHeader';
import { FormDOB, FormInput, FormPassword, FormSelect } from '../components/forms';
import jsPDF from 'jspdf';
import moment from 'moment';

const AccountDetails = () => {
    const navigate = useNavigate();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const totalFiatAmount = useSelector(state => state.account.totalFiatBalance);
    const totalCryptoBalance = useSelector(state => state.account.totalCryptoBalance) || 0;
    const { getClientFeeSetting } = useCommonFunctions();

    const email = localStorage.getItem('email');
    const paymentProviderId = localStorage.getItem('paymentProviderId');
    const [data, setData] = useState([]);
    const [formValue, setFormValue] = useState();
    const [userData, setUserData] = useState({
        password: '',
        confirmPassword: '',
    });
    const [clientBalances, setClientBalances] = useState(null);
    const [downloadCsv, setDownloadCsv] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [activeTab, setActiveTab] = useState('personalInfo');
    const [errorMsg, setErrorMsg] = useState('');
    const { id } = useParams();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [whiteLabelData, setWhiteLabelData] = useState([]);
    const [openDesktopPopover, setOpenDesktopPopover] = useState(false);
    const [openMobilePopover, setOpenMobilePopover] = useState(false);
    const ActionButton = ({ icon, onClick }) => (
        <Button onClick={onClick} icon={icon} className="mr-2 bg-[#F4F4F5] w-8 h-8"></Button>
    );

    const getClientsDetails = async () => {
        const body = {
            email,
            clientId: id,
            paymentProviderId,
        };

        try {
            const response = await apiRequest('/get-single-client-data', 'POST', body);

            if (response.success) {
                if (response.data) {
                    setData(response.data);
                    const initialFormValue = {
                        ...response.data,
                        twoFA: response.data?.twoFA?.enabled || false,
                        dob: response.data?.dob ? dayjs(response.data?.dob) : null,
                    };
                    setFormValue(initialFormValue);
                    form.setFieldsValue(initialFormValue);
                    dispatch(singleClientData(response.data));
                }
            } else {
                message.error(response.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchFeeSetting = async () => {
        const body = { email, clientId: id, transactionType: 'Deposit', currencyType: 'fiat' };
        try {
            await getClientFeeSetting(body);
        } catch (error) {
            console.error('Error fetching fee setting', error);
        }
    };

    const getClientsBalances = async () => {
        const body = {
            clientId: id,
            paymentProviderId,
            email,
        };
        try {
            const response = await apiRequest('/get-client-balances', 'POST', body);

            if (response.success) {
                if (response.data) {
                    setClientBalances(response.data);
                    dispatch(clientFiatBalance(response?.data));
                    dispatch(setBalancesData(response.data));
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getClientsDetails();
        getClientsBalances();
        getCryptoBalances(dispatch, id, paymentProviderId, email);
        getWhiteLableList();
        fetchFeeSetting();
    }, [id]);

    const handleCloseEditModal = () => {
        setEditModal(false);
        setErrorMsg('');
        form.resetFields();
        setUserData({
            password: '',
            confirmPassword: '',
        });
    };

    const modalFields = [
        {
            label: 'Account number:',
            placeholder: 'Enter account number',
            name: 'accountNumber',
            value: formValue?.accountNumber,
            rules: [
                {
                    required: true,
                    message: 'Account Number is required',
                },
                {
                    pattern: /^[A-Za-z0-9]*$/,
                    message: 'Account Number must contain only Latin letters and numbers',
                },
            ],
        },
        {
            label: 'Name:',
            type: 'text',
            placeholder: 'Enter account holder name',
            name: 'name',
            value: formValue?.name,
            rules: [
                {
                    pattern: /^[A-Za-z0-9\s]*$/,
                    message: 'Name must contain only Latin letters, numbers, and spaces',
                },
            ],
        },
        {
            label: 'Birthday:',
            placeholder: 'Enter Birthday',
            type: 'text',
            name: 'dob',
            value: formValue?.dob,
        },
        {
            label: 'Phone number:',
            type: 'number',
            placeholder: 'Enter phone number',
            name: 'phoneNumber',
            value: formValue?.phoneNumber,
            rules: [
                {
                    pattern: /^[0-9\s\-()+]*$/,
                    message: 'Phone Number can contain only numbers and "+" sign',
                },
            ],
        },
        {
            label: 'Address:',
            type: 'text',
            placeholder: 'Enter address',
            name: 'address',
            value: formValue?.address,
        },
        {
            label: 'City:',
            type: 'text',

            placeholder: 'Enter city',
            name: 'city',
            value: formValue?.city,
            rules: [
                {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'City must contain only Latin letters and spaces',
                },
            ],
        },
        {
            label: 'Country:',
            type: 'text',
            placeholder: 'Enter Country',
            name: 'country',
            value: formValue?.country,
            rules: [
                {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Country must contain only Latin letters and spaces',
                },
            ],
        },
        {
            label: 'Postcode:',
            type: 'text',
            placeholder: 'Enter postcode',
            name: 'postCode',
            value: formValue?.postCode,
        },

        {
            label: 'Email:',
            type: 'text',
            placeholder: 'Enter email',
            name: 'clientEmail',
            value: formValue?.clientEmail,
            rules: [
                {
                    required: true,
                    message: 'Please input your email!',
                },
                {
                    type: 'email',
                    message: 'Please enter a valid email address!',
                },
            ],
        },
    ];

    const updateClientDetails = async clientData => {
        const formValues = clientData || (await form.validateFields());

        const body = {
            email,
            clientId: formValue.clientId,
            accountNumber: formValues.accountNumber,
            name: formValues.name,
            phoneNumber: formValues.phoneNumber,
            address: formValues.address,
            city: formValues.city,
            country: formValues.country,
            postCode: formValues.postCode,
            clientEmail: formValues.clientEmail,
            whiteLabelId: formValues.whiteLabelId,
            active: formValues.active,
            thresholdProfile: formValues.thresholdProfile,
            feeProfile: formValues.feeProfile,
            ...(formValue.dob && { dob: formValue.dob }),
        };

        try {
            const response = await apiRequest('/update-single-client-data', 'POST', body);

            if (response.success) {
                if (response.data) {
                    const originalData = {};
                    const newData = {};
                    // Loop to chekck the changed fields
                    Object.keys(body).forEach(key => {
                        if (data[key] !== response.data[key]) {
                            originalData[key] = data[key] || ''; // Old value
                            newData[key] = response.data[key]; // New value
                        }
                    });
                    // Audit Trail entry on update account
                    const auditTrailBody = {
                        userId: email,
                        pageUrl: window.location.href,
                        eventType: 'Update Account',
                        ipAddress: localStorage.getItem('ip') || '',
                        userAgent: window.navigator.userAgent,
                        originalData,
                        newData,
                    };

                    addAuditTrail(auditTrailBody);
                    handleCloseEditModal();
                    getClientsDetails();
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateClientPassword = async () => {
        const formValues = await form.validateFields();

        const body = {
            email,
            clientEmail: data.clientEmail,
            newPassword: formValues.password,
        };

        try {
            const response = await apiRequest('/changePassword-client', 'POST', body);

            if (response.success) {
                if (response.data) {
                    handleCloseEditModal();
                    getClientsDetails();
                }
            } else {
                message.error(response.error);
                setErrorMsg(response.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTableChange = tab => {
        setUserData({
            password: '',
            confirmPassword: '',
        });
        setErrorMsg('');
        setActiveTab(tab);
        form.resetFields();
    };

    const getWhiteLableList = async () => {
        const body = {
            email,
            filterParams: {
                limit: 100,
            },
        };

        try {
            const response = await apiRequest('/get-all-juno-white-listing', 'POST', body);

            if (response.success || response.data) {
                const options = response.data.whitelabelList.map(
                    ({ whitelableName, whitelabelId }) => ({
                        label: whitelableName,
                        value: whitelabelId,
                    }),
                );
                setWhiteLabelData([{ label: 'None', value: 'none' }, ...options]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectChange = (fieldName, value, shouldUpdate = false) => {
        setFormValue(prevState => {
            const updatedState = {
                ...prevState,
                [fieldName]: value,
            };

            form.setFieldsValue({ [fieldName]: value });
            if (shouldUpdate) {
                updateClientDetails(updatedState);
            }
            return updatedState;
        });
    };

    const feeItems = (
        <Menu onClick={e => handleSelectChange('feeProfile', e.key, true)}>
            {feeProfileOptions.map(option => (
                <Menu.Item key={option.value}>{option.label}</Menu.Item>
            ))}
        </Menu>
    );

    const thresholdItems = (
        <Menu onClick={e => handleSelectChange('thresholdProfile', e.key, true)}>
            {thresholdProfileOptions.map(option => (
                <Menu.Item key={option.value}>{option.label}</Menu.Item>
            ))}
        </Menu>
    );

    const totalAmount = Number(totalCryptoBalance) + Number(totalFiatAmount);

    const generatePDF = async () => {
        const imageUrl = `${imageBaseUrl}/adminUi/admin-logo.svg`;
        try {
            const response = await fetch(imageUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch image: ${response.statusText}`);
            }

            const contentType = response.headers.get('content-type');

            let dataUrl;
            if (contentType.includes('svg')) {
                const svgText = await response.text();
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const img = new Image();

                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    dataUrl = canvas.toDataURL('image/png');
                    createPDF(dataUrl, img.width, img.height);
                };

                img.onerror = () => {
                    console.error('Failed to load SVG image into the canvas');
                };

                img.src = 'data:image/svg+xml;base64,' + btoa(svgText);
            } else {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.src = imageUrl;
                img.onload = () => {
                    createPDF(img, 50, 10);
                };
            }
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const createPDF = (dataUrl, imgWidth, imgHeight) => {
        const maxWidth = 50;
        const maxHeight = 10;

        let width = imgWidth;
        let height = imgHeight;

        const aspectRatio = width / height;
        if (width > maxWidth || height > maxHeight) {
            if (width / maxWidth > height / maxHeight) {
                width = maxWidth;
                height = maxWidth / aspectRatio;
            } else {
                height = maxHeight;
                width = maxHeight * aspectRatio;
            }
        }

        const accountData = {
            status: data.active ? 'Active' : 'Inactive',
            name: data.name || 'N/A',
            email: data.clientEmail,
            accountId: data.accountNumber || 'N/A',
            totalBalance: `${formatAmount(totalFiatAmount)}`,
            phone: data.phoneNumber || 'N/A',
            address: data.address || 'N/A',
            city: data.city || 'N/A',
            postalCode: data.postCode || 'N/A',
            country: data.country || 'N/A',
            registrationDate: moment(data?.createdAt).format('D.MM.YY HH:mm') || 'N/A',
        };

        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.setFontSize(14);
        pdf.setFont('helvetica', 'bold');

        pdf.addImage(dataUrl, 'PNG', 10, 5, width / 1.5, height / 1.5);

        // Divider line below the image
        const dividerY = 8 + height / 1.5; // Space 10mm below the image
        pdf.line(10, dividerY, 200, dividerY); // Horizontal line across the width of the page

        // Title
        pdf.text('Account Details', 10, dividerY + 15);

        // Placeholder Image (120x120px, gray, with border-radius)
        pdf.setFillColor(200, 200, 200); // Light gray color
        pdf.roundedRect(10, dividerY + 25, 40, 40, 5, 5, 'F'); // Positioned at (10, 25), 40x40mm with radius 5

        // Account status and user info to the right of the image
        let startX = 55;
        let startY = dividerY + 31;

        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`Status:`, startX, startY);
        pdf.setFont('helvetica', 'normal');
        if (accountData.status === 'Active') {
            pdf.setTextColor(0, 128, 0); // Green for Active
        } else {
            pdf.setTextColor(0, 0, 0); // Gray for In active
        }
        pdf.text(accountData.status, startX + 17, startY);
        pdf.setTextColor(0, 0, 0); // Reset color for other text

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('Name:', startX, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.name, startX + 15, startY);

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('Email:', startX, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.email, startX + 15, startY);

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('Account ID:', startX, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.accountId, startX + 25, startY);

        // Space and divider
        startY += 15;
        pdf.line(10, startY, 200, startY); // Horizontal line

        // Total Balance
        startY += 10;
        pdf.setFontSize(14);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`Total Balance:`, 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(`$ ${accountData.totalBalance}`, 180, startY, { align: 'right' }); // Right-aligned at 180mm

        // Space and divider
        startY += 6;
        pdf.line(10, startY, 200, startY);

        // Additional details
        startY += 10;
        pdf.setFontSize(12);

        pdf.setFont('helvetica', 'bold');
        pdf.text('Phone:', 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.phone, 180, startY, { align: 'right' });

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('Address:', 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.address, 180, startY, { align: 'right' });

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('City:', 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.city, 180, startY, { align: 'right' });

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('ZIP / Postal code:', 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.postalCode, 180, startY, { align: 'right' });

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('Country:', 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.country, 180, startY, { align: 'right' });

        startY += 10;
        pdf.setFont('helvetica', 'bold');
        pdf.text('Registration Date:', 10, startY);
        pdf.setFont('helvetica', 'normal');
        pdf.text(accountData.registrationDate, 180, startY, { align: 'right' });

        // Save the PDF
        pdf.save('AccountDetails.pdf');
    };
    const generatePopoverContent = closePopover => (
        <div className="rounded-lg">
            <button
                className="flex justify-between items-center gap-3 px-3 text-sm font-medium text-[#51525C]"
                onClick={() => {
                    closePopover();
                    setDownloadCsv(true);
                }}>
                Balances
            </button>
            <button
                className="flex justify-between items-center gap-3 px-3 text-sm font-medium text-[#51525C] mt-2"
                onClick={() => {
                    closePopover();
                    generatePDF();
                }}>
                Account details
            </button>
        </div>
    );
    return (
        <>
            <Sidebar>
                <div className="gap-[16px] m-6 lg:block hidden h-screen">
                    <BreadCrumHeader
                        subTabs={[
                            {
                                href: '/accounts',
                                icon: 'multipleUsersIcon.svg',
                                title: 'Accounts',
                            },
                        ]}
                        currentTab={{ title: data.name }}
                    />
                    <div className="bg-white rounded-xl">
                        <div className="grid grid-cols-3 h-[90vh] ">
                            <div className="border-r border-[#E4E4E7] h-[90vh] overflow-hidden">
                                <div className="flex justify-between items-center p-6">
                                    <h1 className="page-title">Account Details</h1>
                                    <div>
                                        <Popover
                                            placement="bottom"
                                            content={() =>
                                                generatePopoverContent(() =>
                                                    setOpenDesktopPopover(false),
                                                )
                                            }
                                            trigger="click"
                                            open={openDesktopPopover}
                                            onOpenChange={open => setOpenDesktopPopover(open)}>
                                            <ActionButton
                                                icon={
                                                    <img
                                                        src={`${imageBaseUrl}${Download}`}
                                                        alt=""
                                                        className="w-4 h-4"
                                                    />
                                                }
                                            />
                                        </Popover>
                                        <ActionButton
                                            icon={
                                                <img
                                                    src={`${imageBaseUrl}${Edit}`}
                                                    alt=""
                                                    className="w-4 h-4"
                                                />
                                            }
                                            onClick={() => setEditModal(true)}
                                        />
                                    </div>
                                </div>
                                <div className="overflow-auto px-6">
                                    <div className="flex items-center gap-4">
                                        <img
                                            className="max-w-[128px] max-h-[128px] rounded-xl object-cover"
                                            src="https://via.placeholder.com/150"
                                            alt="Avatar"
                                        />
                                        <div className="w-full">
                                            <div className="flex justify-between items-center mb-4">
                                                <p className="text-sm font-normal text-darkGray">
                                                    Status:
                                                </p>
                                                <p
                                                    className={`${data?.active ? 'text-green-800 bg-green-100' : 'bg-gray-100 text-gray-600'} rounded-full py-1 px-2 text-sm font-medium`}>
                                                    {data?.active ? 'Active' : 'Inactive'}
                                                </p>
                                            </div>
                                            <h2 className="text-base font-semibold">{data.name}</h2>
                                            <p className="text-sm font-normal text-darkGray">
                                                {data.clientEmail}
                                                <CopyButton msg={data.clientEmail} />
                                            </p>
                                            <Divider className="my-2" />
                                            <p className="text-sm font-normal text-darkGray">
                                                Account ID:
                                                <span className="">{data.accountNumber}</span>
                                                <CopyButton msg={data.accountNumber} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <Divider className="mb-0 mt-6" />
                                    <div className="py-5 px-6">
                                        {/* TODO: Commenting Crypto code 
                                        {/* <div className="flex justify-between items-center">
                                            <p className="text-sm font-normal text-darkGray">
                                                Juno Money:
                                            </p>
                                            <p className="text-base font-normal text-[#18181B]">
                                                {`$ ${formatAmount(totalFiatAmount) || 0}`}
                                            </p>
                                        </div>
                                        <div className="flex justify-between items-center mt-2">
                                            <p className="text-sm font-normal text-darkGray">
                                                Juno X:
                                            </p>
                                            <p className="text-base font-normal text-[#18181B]">
                                                {`$ ${formatAmount(totalCryptoBalance) || 0}`}
                                            </p>
                                        </div> 
                                         <Divider className="my-3" /> */}
                                        <div className="flex justify-between items-center ">
                                            <p className="text-base font-medium text-primary">
                                                Total Balance:
                                            </p>
                                            <p className="text-base font-semibold text-primary">
                                                {`$ ${formatAmount(totalFiatAmount) || 0}`}
                                                {/* TODO: Commenting Crypto code */}
                                                {/* {`$ ${formatAmount(totalAmount)}`} */}
                                            </p>
                                        </div>
                                    </div>
                                    {/* <Divider className="m-0" /> */}
                                </div>
                                <div className="">
                                    {/* <div className="p-6">
                                        <div className="flex justify-between items-center">
                                            <p className="text-sm font-normal text-darkGray">
                                                Fee Profile:
                                            </p>
                                            <div className="flex justify-center items-center gap-[1px]">
                                                <div className="border bg-[#F4F4F5] text-sm font-medium py-[1px] px-2 rounded-l-lg">
                                                    {formValue?.feeProfile}
                                                </div>
                                                <Dropdown
                                                    overlay={feeItems}
                                                    placement="bottomRight"
                                                    className="border bg-[#F4F4F5] p-1 rounded-r-lg"
                                                    trigger={['click']}>
                                                    <DownOutlined />
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="flex justify-between items-center mt-2">
                                            <p className="text-sm font-normal text-darkGray">
                                                Threshold Profile:
                                            </p>
                                            <div className="flex justify-center items-center gap-[1px]">
                                                <div className="border bg-[#F4F4F5] text-sm font-medium py-[1px] px-2 rounded-l-lg">
                                                    {formValue?.thresholdProfile}
                                                </div>
                                                <Dropdown
                                                    overlay={thresholdItems}
                                                    placement="bottomRight"
                                                    className="border bg-[#F4F4F5] p-1 rounded-r-lg"
                                                    trigger={['click']}>
                                                    <DownOutlined />
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div> */}
                                    <Divider className="mb-5 mt-0" />
                                </div>
                                <Details data={data} />
                            </div>
                            <div className="col-span-2 p-6 account-details">
                                <Tabs
                                    defaultActiveKey="1"
                                    items={accountDetailsNav.slice(1)}
                                    className="mb-0"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:hidden bg-white">
                    <div className="flex justify-between items-center p-5 bg-[#F7F7F7]">
                        <h1
                            className="font-semibold text-base cursor-pointer"
                            onClick={() => navigate(-1)}>
                            <LeftOutlined className="mr-2" />
                            <span>Account</span>
                        </h1>
                        <div>
                            <Popover
                                placement="bottom"
                                content={() =>
                                    generatePopoverContent(() => setOpenMobilePopover(false))
                                }
                                trigger="click"
                                open={openMobilePopover}
                                onOpenChange={open => setOpenMobilePopover(open)}>
                                <Button
                                    icon={
                                        <img
                                            src={`${imageBaseUrl}${Download}`}
                                            alt="download"
                                            className="w-3 h-4"
                                        />
                                    }
                                    className="mr-2 border-0 bg-[#F7F7F7] "
                                />
                            </Popover>

                            <Button
                                icon={
                                    <img
                                        src={`${imageBaseUrl}${Edit}`}
                                        alt="edit"
                                        className="w-4 h-4"
                                    />
                                }
                                onClick={() => setEditModal(true)}
                                className="border-0 bg-[#F7F7F7] "></Button>
                        </div>
                    </div>
                    <div className="flex items-center gap-4 p-5">
                        <img
                            className="w-[88px] h-[88px] rounded-xl object-cover"
                            src="https://via.placeholder.com/150"
                            alt="Avatar"
                        />
                        <div className="w-full">
                            <div className="flex justify-between items-center mb-5">
                                <p className="text-sm font-normal text-darkGray">Status:</p>
                                <p
                                    className={`${data?.active ? 'text-green-800 bg-green-100' : 'bg-gray-100 text-gray-600'} rounded-full py-1 px-2 text-sm font-medium`}>
                                    {data?.active ? 'Active' : 'Inactive'}
                                </p>
                            </div>
                            <h2 className="text-base font-semibold">{data.name}</h2>
                            <p className="text-sm font-normal text-darkGray">
                                Account ID: <span className="">{data.accountNumber}</span>
                                <CopyButton msg={data.accountNumber} />
                            </p>
                        </div>
                    </div>
                    <Divider className="my-2" />
                    <div className="py-5 px-6">
                        {/* TODO: Commenting CRYPTO code */}
                        {/* <div className="flex justify-between items-center">
                            <p className="text-sm font-normal text-darkGray">Juno Money:</p>
                            <p className="text-base font-normal text-[#18181B]">
                                {`$ ${formatAmount(totalFiatAmount)}`}
                            </p>
                        </div>
                        <div className="flex justify-between items-center mt-2">
                            <p className="text-sm font-normal text-darkGray">Juno X:</p>
                            <p className="text-base font-normal text-[#18181B]">
                                {`$ ${formatAmount(totalCryptoBalance)}`}
                            </p>
                        </div>
                        <Divider className="my-3" /> */}
                        <div className="flex justify-between items-center ">
                            <p className="text-base font-medium text-primary">Total Balance:</p>
                            <p className="text-base font-semibold text-primary">
                                {/* TODO: Commenting CRYPTO code */}
                                {/* {`$ ${formatAmount(totalAmount)}`} */}
                                {`$ ${formatAmount(totalFiatAmount)}`}
                            </p>
                        </div>
                    </div>
                    <Divider className="m-0" />
                    {/* TODO: Commenting CRYPTO code */}
                    {/* <div className="p-6">
                        <div className="flex justify-between items-center">
                            <p className="text-sm font-normal text-darkGray">Fee Profile:</p>
                            <div className="flex justify-center items-center gap-[1px]">
                                <div className="border bg-[#F4F4F5] text-sm font-medium py-[1px] px-2 rounded-l-lg">
                                    {formValue?.feeProfile}
                                </div>
                                <Dropdown
                                    overlay={feeItems}
                                    placement="bottomRight"
                                    className="border bg-[#F4F4F5] p-1 rounded-r-lg"
                                    trigger={['click']}>
                                    <DownOutlined />
                                </Dropdown>
                            </div>
                        </div>
                        <div className="flex justify-between items-center mt-2">
                            <p className="text-sm font-normal text-darkGray">Threshold Profile:</p>
                            <div className="flex justify-center items-center gap-[1px]">
                                <div className="border bg-[#F4F4F5] text-sm font-medium py-[1px] px-2 rounded-l-lg">
                                    {formValue?.thresholdProfile}
                                </div>
                                <Dropdown
                                    overlay={thresholdItems}
                                    placement="bottomRight"
                                    className="border bg-[#F4F4F5] p-1 rounded-r-lg"
                                    trigger={['click']}>
                                    <DownOutlined />
                                </Dropdown>
                            </div>
                        </div>
                    </div> */}
                    <div className="bg-[#F7F7F7]">
                        <Tabs
                            defaultActiveKey="0"
                            items={accountDetailsNav}
                            className="transaction-detail account-tabs-mob hide-scrollbar"
                        />
                    </div>
                </div>
            </Sidebar>
            <DownloadCsvModal
                data={[data]}
                open={downloadCsv}
                handleClose={() => setDownloadCsv(false)}
                name="singleClient"
            />
            <CommonModal
                title="Edit Personal Information"
                open={editModal}
                handleClose={handleCloseEditModal}
                className={'common-mobile-view account-model-view'}
                footerText={activeTab === 'personalInfo' ? 'Save' : 'Reset Password'}
                error={errorMsg}
                onFinish={activeTab === 'personalInfo' ? updateClientDetails : updateClientPassword}
                form={form}>
                <div className="mt-10 lg:mt-[30px] h-[75vh] overflow-y-auto hide-scrollbar px-[7px] modal-form">
                    <div className="bg-[#F4F4F5] w-full p-[2px] flex h-10 rounded-lg">
                        <button
                            onClick={() => handleTableChange('personalInfo')}
                            className={`flex items-center justify-center py-[10px] px-2 w-full h-full bg-[#F4F4F5] border-none text-sm font-medium ${activeTab === 'personalInfo' && 'rounded-lg bg-white'}`}>
                            Personal info
                        </button>
                        <button
                            onClick={() => handleTableChange('password')}
                            className={`flex items-center justify-center py-[10px] px-2 w-full h-full bg-[#F4F4F5] border-none text-sm font-medium ${activeTab === 'password' && 'rounded-lg bg-white'}`}>
                            Password
                        </button>
                    </div>
                    <Form
                        form={form}
                        layout="horizontal"
                        colon={false}
                        className="lg:mt-10 mt-5 lg:pr-2 modal-form">
                        <>
                            {activeTab === 'personalInfo' ? (
                                <>
                                    {modalFields.map((field, key) =>
                                        field.name === 'dob' ? (
                                            <FormDOB
                                                label={field.label}
                                                name={field.name}
                                                key={key}
                                                placeholder={field.placeholder}
                                                format="DD/MM/YYYY"
                                                defaultValue={field.value ? dayjs(field.value) : ''}
                                                onChange={date => {
                                                    setFormValue({
                                                        ...formValue,
                                                        [field.name]: date
                                                            ? dayjs(date).toISOString()
                                                            : '',
                                                    });
                                                    form.setFieldValue({
                                                        [field.name]: date
                                                            ? dayjs(date).toISOString()
                                                            : '',
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <FormInput
                                                label={field.label}
                                                name={field.name}
                                                key={key}
                                                placeholder={field.placeholder}
                                                defaultValue={field.value}
                                                type={field.type}
                                                rules={field.rules}
                                            />
                                        ),
                                    )}
                                    <FormSelect
                                        label="Fee Profile:"
                                        name="feeProfile"
                                        defaultValue={formValue?.feeProfile}
                                        placeholder="Select feeProfile"
                                        options={feeProfileOptions}
                                        onChange={val => handleSelectChange('feeProfile', val)}
                                    />
                                    <FormSelect
                                        label="Threshold Profile:"
                                        name="thresholdProfile"
                                        defaultValue={formValue?.thresholdProfile}
                                        placeholder="Select thresholdProfile"
                                        options={thresholdProfileOptions}
                                        onChange={val =>
                                            handleSelectChange('thresholdProfile', val)
                                        }
                                    />
                                    <FormSelect
                                        label="Whitelabel:"
                                        name="whiteLabelId"
                                        placeholder="Select Whitelabel"
                                        options={whiteLabelData.map((item, key) => ({
                                            value: item.value,
                                            label: item.label,
                                            key: key,
                                        }))}
                                        defaultValue={formValue?.whiteLabelId}
                                        onChange={value =>
                                            handleSelectChange('whiteLabelId', value)
                                        }
                                    />
                                    <FormSelect
                                        label="Status:"
                                        name="active"
                                        placeholder="Select Status"
                                        options={[
                                            { value: true, label: 'Active' },
                                            { value: false, label: 'Inactive' },
                                        ]}
                                        defaultValue={formValue?.active ? 'Active' : 'Inactive'}
                                        onChange={value => handleSelectChange('active', value)}
                                    />
                                </>
                            ) : (
                                <>
                                    <FormPassword
                                        label="New Password:"
                                        name="password"
                                        placeholder="Create Password"
                                        dataE2e="create-password"
                                        rules={[
                                            { required: true, message: 'Please enter a password' },
                                        ]}
                                        onChange={value => handleSelectChange('password', value)}
                                    />
                                    <FormPassword
                                        label="Confirm Password:"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        dataE2e="confirm-password"
                                        onChange={value =>
                                            handleSelectChange('confirmPassword', value)
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (
                                                        !value ||
                                                        getFieldValue('password') === value
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error('The two passwords do not match'),
                                                    );
                                                },
                                            }),
                                        ]}
                                    />
                                </>
                            )}
                        </>
                    </Form>
                </div>
            </CommonModal>
        </>
    );
};

export default AccountDetails;
