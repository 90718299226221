import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import BreadCrumHeader from './common/BreadCrumHeader';
import { Table, Space, Input, DatePicker, Button, Pagination, Spin, message } from 'antd';
import {
    SearchOutlined,
    CalendarOutlined,
    LeftOutlined,
    DownOutlined,
    UpOutlined,
} from '@ant-design/icons';
import { Download, MobileFilter } from '../utils/Images';
import moment from 'moment';
import CustomPagination from './layout/CustomPagination';
import { toFixedTrunc } from '../utils/common';
import useDebounce from '../utils/useDebounce';
import { singleClientData } from '../redux/actions/accountActions';
import apiRequest from '../utils/api';
import BalanceHistoryFilterDrawer from './modals/BalanceHistoryFilterDrawer';
import DownloadCsvModal from './modals/DownloadCsvModal';
import { fiatCurrencies } from '../utils/constant';
import { getCurrencySymbol } from '../utils/common';

const BalanceHistory = props => {
    const { columns, ExpandedRow } = props;

    const [data, setData] = useState([]);
    const [enableSearch, setEnableSearch] = useState(false);
    const [expandedRowKey, setExpandedRowKey] = useState(null);
    const [pagination, setPagination] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [sortConfig, setSortConfig] = useState({ sortBy: '', orderBy: '' });
    const [searchText, setSearchText] = useState('');
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [csvModal, setCsvModal] = useState(false);
    const { clientData } = useSelector(state => state.account);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const navigate = useNavigate();
    const { id, currency } = useParams();

    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();

    const email = localStorage.getItem('email');
    const paymentProviderId = localStorage.getItem('paymentProviderId');

    useEffect(() => {
        if (Object.keys(clientData).length === 0) {
            getClientsDetails();
        }
    }, []);
    const getClientsDetails = async () => {
        const body = {
            email,
            clientId: id,
            paymentProviderId,
        };
        try {
            const response = await apiRequest('/get-single-client-data', 'POST', body);
            if (response?.success) {
                dispatch(singleClientData(response.data));
            } else {
                message.error(response.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getLedgerBalance();
    }, [pagination, sortConfig, searchText, filters]);
    const getLedgerBalance = useCallback(
        async (limit = 10, isDownloadCsv = false) => {
            const body = {
                email,
                clientId: id,
                paymentProviderId,
                currency: currency,
                sortBy: sortConfig?.sortBy,
                orderBy: sortConfig?.orderBy,
                page: pagination,
                searchText: searchText,
                limit,
                ...filters,
            };
            try {
                setLoading(true);
                const response = await apiRequest('/get-client-ledger-balances', 'POST', body);
                if (response?.success || response?.data) {
                    if (isDownloadCsv) {
                        setCsvData(response?.data?.ledgerBalanceList);
                        setCsvModal(true);
                        setIsDownloadCsv(false);
                    } else {
                        setData(response?.data?.ledgerBalanceList);
                        setTotalPages(response?.data?.totalCount);
                    }
                } else {
                    message.error(response.error);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        },
        [pagination, sortConfig, searchText, filters],
    );

    const handleDateRange = (_, range) => {
        let [fromDate, toDate] = range;
        fromDate = moment.utc(fromDate, 'DD-MM-YYYY').startOf('day');
        toDate = moment.utc(toDate, 'DD-MM-YYYY').endOf('day');

        setPagination(1);
        setFilters(prevState => ({
            ...prevState,
            fromDate,
            toDate,
        }));
    };

    const searchTransaction = useDebounce(value => {
        setPagination(1);
        setSearchText(value);
    }, 500);

    const handleTableChange = (newPagination, filters, sorter) => {
        const { current } = newPagination;
        const { columnKey, order } = sorter;
        setPagination(current);

        let sortBy, orderBy;
        if (order === 'ascend') {
            sortBy = columnKey;
            orderBy = 'asc';
        } else if (order === 'descend') {
            sortBy = columnKey;
            orderBy = 'desc';
        }
        setSortConfig({ sortBy, orderBy });
    };
    const handlePagination = page => {
        setPagination(page);
    };

    const handleExpandRow = key => {
        setExpandedRowKey(expandedRowKey === key ? null : key);
    };

    const handleDownloadCsvFile = () => {
        getLedgerBalance(totalPages, true);
    };

    const getCurrencyText = currencyShortName => {
        const currenctCurrency = fiatCurrencies.find(
            currency => currency.symbol === currencyShortName,
        );
        return <p>{`${currenctCurrency?.name} (${currenctCurrency?.symbol})`}</p>;
    };

    return (
        <div>
            <DownloadCsvModal
                data={csvData}
                open={csvModal}
                handleClose={() => setCsvModal(false)}
                name="ledgerBalance"
            />
            <BalanceHistoryFilterDrawer
                open={filterModal}
                setOpen={setFilterModal}
                setFilters={setFilters}
                setPage={setPagination}
            />
            <div className="gap-[16px] m-6 lg:block hidden h-screen">
                <BreadCrumHeader
                    subTabs={[
                        {
                            href: '/accounts',
                            icon: 'multipleUsersIcon.svg',
                            title: 'Accounts',
                        },
                        {
                            href: `/accounts/${id}`,
                            title: clientData?.name,
                        },
                    ]}
                    currentTab={{ title: 'Balance History', icon: 'balanceHistory.svg' }}
                />
                <div className="desktop-page-block hidden lg:flex flex-col p-6 bg-white rounded-t-xl">
                    <div className="flex justify-between items-center">
                        <p className="page-title">Balance history</p>
                        <Space>
                            <Input
                                placeholder="Search transaction ID.."
                                data-e2e="search-box"
                                suffix={<SearchOutlined className="text-lg" />}
                                className="w-full xl:w-[460px] p-2"
                                name="searchText"
                                value={searchText}
                                onChange={e => {
                                    setSearchText(e.target.value);
                                    searchTransaction(e.target.value);
                                }}
                                allowClear
                            />
                            <RangePicker
                                className="w-[300px] p-2"
                                suffixIcon={<CalendarOutlined className="text-lg" />}
                                format="DD-MM-YYYY"
                                onChange={handleDateRange}
                            />
                            <Button
                                className="!w-[40px] !h-[40px] bg-[#F4F4F5]"
                                onClick={handleDownloadCsvFile}
                                icon={
                                    <img
                                        src={`${imageBaseUrl}${Download}`}
                                        alt="download"
                                        className="w-5 h-5 inline"
                                    />
                                }></Button>
                        </Space>
                    </div>
                    <p className="text-sm">{getCurrencyText(currency)}</p>
                </div>

                <div className="lg:block hidden bg-[#F7F7F7] w-full accounts-page">
                    <div className="bg-white rounded-lg">
                        <Table
                            dataSource={data}
                            columns={columns}
                            className="settings-table border-[#E4E4E7] border-[1px] border-solid rounded-b-lg custom-table"
                            scroll={true}
                            rowKey={record => record._id}
                            loading={loading}
                            pagination={{
                                position: ['bottomCenter'],
                                itemRender: CustomPagination,
                                showSizeChanger: false,
                                current: pagination,
                                total: totalPages,
                            }}
                            onChange={handleTableChange}
                        />
                    </div>
                </div>
            </div>

            {/* Tablet & Mobile Screen */}
            <div className="flex flex-col lg:hidden">
                <div className=" p-3 border-y-[1px]">
                    <div className="lg:block flex items-center">
                        {enableSearch ? (
                            <Input
                                placeholder="Search transaction ID"
                                prefix={<LeftOutlined onClick={() => setEnableSearch(false)} />}
                                suffix={<SearchOutlined />}
                                className="sm:!w-[351px] md:!w-[696px] w-full p-2"
                                name="searchText"
                                value={searchText}
                                onChange={e => {
                                    setSearchText(e.target.value);
                                    searchTransaction(e.target.value);
                                }}
                                allowClear
                            />
                        ) : (
                            <div className="flex w-full justify-between">
                                <div className="flex items-start">
                                    <LeftOutlined
                                        className="mt-2"
                                        onClick={() => navigate(`/accounts/${id}`)}
                                    />
                                    <div className="flex flex-col">
                                        <h2 className="text-base font-semibold pr-2 ml-4">
                                            Balance history
                                        </h2>
                                        <p className="text-sm ml-4 mt-1 text-[#51525C]">
                                            {getCurrencyText(currency)}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-5">
                                    <SearchOutlined
                                        className="cursor-pointer text-xl"
                                        onClick={() => setEnableSearch(true)}
                                    />
                                    <img
                                        src={`${imageBaseUrl}${Download}`}
                                        onClick={handleDownloadCsvFile}
                                        className=" cursor-pointer w-5 h-5"
                                        alt="download"
                                    />
                                    <img
                                        src={`${imageBaseUrl}${MobileFilter}`}
                                        className="w-4 h-3 cursor-pointer"
                                        onClick={() => setFilterModal(true)}
                                        alt="mobileFilter"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {loading ? (
                    <div className="flex items-center justify-center h-[70vh] ">
                        <Spin />
                    </div>
                ) : (
                    data?.map((item, key) => {
                        const isExpanded = expandedRowKey === key;
                        return (
                            <div key={key} className="bg-white border-b border-[#E4E4E7]">
                                <div className="flex justify-between items-center p-3 cursor-pointer">
                                    <div className="flex gap-2 items-center">
                                        <div>
                                            <h1 className="text-sm font-medium pb-1 text-[rgb(24,24,27)]">
                                                {item?.type || 'N/A'}
                                            </h1>
                                            <p className="font-normal text-xs text-darkGray">
                                                {moment(item?.creationDate).format(
                                                    'DD/MM/YYYY - HH:mm',
                                                ) || 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex gap-4 items-center">
                                        <div className="flex" onClick={() => handleExpandRow(key)}>
                                            <div className="mr-4 flex flex-col items-end">
                                                <h1 className="text-sm font-medium pb-1 text-[rgb(24,24,27)]">
                                                    {item?.transactionId}
                                                </h1>
                                                <p className="font-normal text-xs text-darkGray">
                                                    {getCurrencySymbol(item?.currency)}{' '}
                                                    {toFixedTrunc(item?.amount, 2)}
                                                </p>
                                            </div>
                                            {isExpanded ? (
                                                <UpOutlined width={10} height={10} />
                                            ) : (
                                                <DownOutlined width={10} height={10} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {isExpanded && (
                                    <div className="p-3">
                                        <ExpandedRow record={item} />
                                    </div>
                                )}
                            </div>
                        );
                    })
                )}

                <Pagination
                    total={totalPages}
                    className="py-3 flex justify-center"
                    current={pagination}
                    onChange={handlePagination}
                    showSizeChanger={false}
                />
            </div>
        </div>
    );
};

export default BalanceHistory;
