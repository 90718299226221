import { Button, Checkbox, Input, Modal, message } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import apiRequest from '../../utils/api';
import { LoadingSkeleton } from '../common/Loading';
import ThemeButton from '../common/ThemeButton';

const LinkMerchantModal = ({ clientId, open, onClose, onSave, onReset }) => {
    const [loading, setLoading] = useState(false);
    const email = localStorage.getItem('email');
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState([]);

    const [selectedMerchants, setSelectedMerchants] = useState([]);

    const handleChange = event => {
        const userId = event.target.name;
        setSelectedMerchants(prev => {
            if (event.target.checked) {
                return [...prev, userId];
            } else {
                return prev.filter(id => id !== userId);
            }
        });
    };

    function handleOnClose() {
        setSearchText('');
        onClose();
    }

    const getMerchants = async () => {
        setLoading(true);
        try {
            const body = {
                clientId: clientId,
                notLinked: true,
                email,
                limit: 200,
                searchQuery: searchText,
            };
            const res = await apiRequest('/fetch-merchant', 'POST', body);
            if (res.success) {
                setData(res.data.merchants);
            } else {
                message.error(res.error);
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const getData = setTimeout(() => {
            if (open) {
                getMerchants();
            }
        }, 500);
        return () => {
            clearTimeout(getData);
            setSelectedMerchants([]);
        };
    }, [searchText, open]);

    return (
        <Modal
            title={<p className="text-2xl font-semibold">Link Merchants</p>}
            open={open}
            centered
            okText="Continue"
            className={'common-mobile-view merchant-mobile-view'}
            okButtonProps={{ className: 'rounded-full px-8 py-3 h-[46px]' }}
            cancelButtonProps={{ className: 'rounded-full px-8 py-3 h-[46px]' }}
            onOk={() => onSave(selectedMerchants)}
            onCancel={handleOnClose}
            footer={
                <>
                    <div className="flex justify-end w-full ">
                        <div className="flex-1 flex ">
                            <ThemeButton
                                style="sm:!w-[140px] !w-[100px] "
                                shape="round"
                                text="Reset"
                                key="reset"
                                action={onReset}
                            />
                        </div>

                        <div className="flex">
                            <ThemeButton
                                style="mr-3 sm:!w-[140px] !w-[100px]"
                                key="cancel"
                                action={handleOnClose}
                                text="Cancel"
                                shape="round"
                                autoWidth={true}
                            />
                            <div className="sm:w-[140px] w-[100px]">
                                <ThemeButton
                                    style="sm:!w-[140px] !w-[100px]"
                                    key="submit"
                                    type="primary"
                                    shape="round"
                                    text="Continue"
                                    action={() => onSave(selectedMerchants)}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }>
            <div className="h-[78vh] md:h-auto overflow-auto hide-scrollbar">
                <p className="text-base text-[#51525C]">
                    Select or search one of multiple merchant you would like to link to this
                    merchant.
                </p>
                <Input
                    onChange={e => setSearchText(e.target.value)}
                    value={searchText}
                    placeholder="Search Merchant, Merchant ID"
                    suffix={<SearchOutlined />}
                    className=" p-2 my-6"
                />
                {loading ? (
                    <div className="border border-[#D1D1D6] rounded-md h-[50vh] md:h-[300px]">
                        <LoadingSkeleton />
                    </div>
                ) : (
                    <>
                        <div className="flex mb-6 gap-2 flex-wrap">
                            {data
                                .filter(user => selectedMerchants.includes(user._id))
                                .map(u => (
                                    <div
                                        key={u._id}
                                        className="p-[3px] flex gap-1 bg-[#F4F4F5] rounded-[4px]">
                                        <span>{u.merchantName}</span>
                                        <CloseOutlined
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setSelectedMerchants(prev => {
                                                    return prev.filter(id => id !== u._id);
                                                })
                                            }
                                        />
                                    </div>
                                ))}
                        </div>
                        <div className="border border-[#D1D1D6] rounded-md h-[50vh] md:h-[300px] overflow-scroll hide-scrollbar">
                            {data.length > 0 ? (
                                data.map(user => (
                                    <div
                                        key={user._id}
                                        className="flex justify-between p-2.5 pr-6 ">
                                        <p className="text-sm text-primary">
                                            {user.merchantName}{' '}
                                            <span className="text-[#70707B]">({user._id})</span>
                                        </p>
                                        <Checkbox
                                            checked={selectedMerchants.includes(user._id)}
                                            name={user._id}
                                            onChange={handleChange}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div className=" p-2.5 pr-6">
                                    <p className="theme-table-empty">No data found!</p>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default LinkMerchantModal;
