import { Button, Divider, Form, Input, Skeleton, Spin, Tabs, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import CommonModal from '../modals/CommonModal';
import { navbarItems, statusCode, transactionStatus, transactionTypes } from '../../utils/constant';
import { useSelector, useDispatch } from 'react-redux';
import apiRequest from '../../utils/api';
import {
    formatAmount,
    getBalancesData,
    getBeneficiaryList,
    useCommonFunctions,
} from '../../utils/common';
import { useParams, useNavigate } from 'react-router-dom';
import TransferModal from '../modals/TransferModal';
import FXFiatModal from '../modals/FXFiatModal';
import DepositFiatModal from '../modals/DepositFiatModal';
import WithdrawalAndTPPFiatModal from '../modals/WithdrawalAndTPPFiatModal';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import ThemeButton from '../common/ThemeButton';
import { useWindowWidth } from '../../utils/common';
import MerchantTransfer from '../modals/MerchantTransfer';

const TransactionTab = () => {
    const dispatch = useDispatch();
    const windowWidth = useWindowWidth();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const {
        clientData,
        balancesData,
        savedBeneficiaryAccounts: beneficiaryList,
        totalFiatBalance,
    } = useSelector(state => state.account);
    const { getClientFeeSetting } = useCommonFunctions();

    const [transactionModal, setTransactionModal] = useState(false);
    const [selectedTab, setSelectedTab] = useState('All');
    const [loading, setLoading] = useState(false);
    const [balanceLoading, setBalanceLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModal, setOpenModal] = useState('');
    const [transactionDetails, setTransactionDetails] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState({});
    const email = localStorage.getItem('email');
    const paymentProvider = localStorage.getItem('paymentProviderId');
    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        getBalancesData(
            dispatch,
            clientData.clientId,
            email,
            clientData.clientEmail,
            setBalanceLoading,
        );
        getBeneficiaryList(dispatch, email, clientData.clientEmail);
    }, []);

    useEffect(() => {
        getTransactionDetails();
    }, [totalFiatBalance]);

    useEffect(() => {
        setSelectedCurrency(balancesData?.[0] || {});
    }, [balancesData]);

    useEffect(() => {
        fetchFeeSetting();
    }, [openModal]);

    const calculateTotals = data => {
        let totalCount = 0;
        let totalUSDAmount = 0;
        for (let key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                totalCount += data[key].count;
                totalUSDAmount += data[key].totalUSDAmount;
            }
        }
        return { totalCount, totalUSDAmount };
    };

    const items = [
        {
            key: 'All',
            label: 'All',
        },
        {
            key: 'Deposit',
            label: 'Deposits',
            type: 'Deposit',
        },
        {
            key: 'Withdrawal',
            label: 'Withdrawals',
            type: 'Withdrawal',
        },
        {
            key: 'Payment Out',
            label: "TPP's",
            type: 'Payment Out',
        },
        {
            key: 'Transfer',
            label: 'Transfers',
            type: 'Transfer',
        },
        {
            key: 'FX',
            label: 'FX',
            type: 'FX',
        },
        {
            key: 'Merchants',
            label: 'Merchants',
            type: 'Merchants',
        },
    ];

    function getStatus(code) {
        for (const [status, codes] of Object.entries(statusCode)) {
            if (codes.includes(code)) {
                return status;
            }
        }
        return 'N/A';
    }
    const getTransactionDetails = async () => {
        const body = {
            email,
            paymentProvider,
            startDate: 'all',
            endDate: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
            clientId: id,
        };

        setLoading(true);
        try {
            const response = await apiRequest('/get-dashboard-transaction-details', 'POST', body);

            setLoading(false);
            if (response.success && response.data) {
                const { transactionType } = response.data;
                const result = {
                    typeTransactions: {},
                    allTransactions: [
                        { type: 'All deposits', label: 'Deposit', value: 0, totalTransaction: 0 },
                        {
                            type: 'All withdrawals',
                            label: 'Withdrawal',
                            value: 0,
                            totalTransaction: 0,
                        },
                        { type: "All TPP's", label: 'Payment Out', value: 0, totalTransaction: 0 },
                        { type: 'All transfers', label: 'Transfer', value: 0, totalTransaction: 0 },
                        { type: 'All FX', label: 'FX', value: 0, totalTransaction: 0 },
                        {
                            type: 'All Merchants',
                            label: 'Merchants',
                            value: 0,
                            totalTransaction: 0,
                        },
                    ],
                };
                transactionType.forEach(item => {
                    if (item.currencyType === 'fiat') {
                        const type = item.type;
                        const status = getStatus(item.statusCode);

                        if (!result.typeTransactions[type]) {
                            result.typeTransactions[type] = {};
                        }
                        if (!result.typeTransactions[type][status]) {
                            result.typeTransactions[type][status] = { count: 0, totalUSDAmount: 0 };
                        }
                        result.typeTransactions[type][status].count += item.count;
                        if (status !== 'failed') {
                            result.typeTransactions[type][status].totalUSDAmount +=
                                item.totalUSDAmount;
                        }
                        // Calculate allTransactions
                        let transactionCategory;
                        switch (type.toLowerCase()) {
                            case 'deposit':
                                transactionCategory = result.allTransactions.find(
                                    t => t.type === 'All deposits',
                                );
                                break;
                            case 'withdrawal':
                                transactionCategory = result.allTransactions.find(
                                    t => t.type === 'All withdrawals',
                                );
                                break;
                            case 'transfer':
                                transactionCategory = result.allTransactions.find(
                                    t => t.type === 'All transfers',
                                );
                                break;
                            case 'payment out':
                                transactionCategory = result.allTransactions.find(
                                    t => t.type === "All TPP's",
                                );
                                break;
                            case 'fx':
                                transactionCategory = result.allTransactions.find(
                                    t => t.type === 'All FX',
                                );
                                break;
                            case 'merchants':
                                transactionCategory = result.allTransactions.find(
                                    t => t.type === 'All Merchants',
                                );
                                break;
                            default:
                                return; // skip if type is not in the predefined categories
                        }

                        transactionCategory.totalTransaction += item.count;
                        if (status !== 'failed') {
                            transactionCategory.value += item.totalUSDAmount;
                        }
                    }
                });
                setTransactionDetails(result);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const createTransaction = async body => {
        try {
            const response = await apiRequest('/create-client-transaction-merchant', 'POST', body);
            if (response.success) {
                await getBalancesData(
                    dispatch,
                    clientData?.clientId,
                    email,
                    clientData?.clientEmail,
                    setBalanceLoading,
                );
                await getTransactionDetails();
                return { success: true };
            } else {
                message.error(response.error);
                return { error: response.error };
            }
        } catch (error) {
            console.error('Error while creating transaction:', error);
        }
    };

    const fetchFeeSetting = async () => {
        if (!openModal) return null;

        const transactionType = openModal === 'Third-party payment' ? 'Payment Out' : openModal;

        const payload = {
            email,
            clientId: id,
            transactionType: transactionType,
            currencyType: 'fiat',
        };

        try {
            await getClientFeeSetting(payload);
        } catch (error) {
            console.error('Error fetching fee setting:', error);
        }
    };

    const openModalAction = modalType => {
        setIsModalOpen(true);
        setOpenModal(modalType);
        setTransactionModal(false);
    };

    const handleClose = () => {
        setTransactionModal(false);
    };

    const onModalClose = () => {
        setIsModalOpen(false);
        setOpenModal('');
    };

    const isEmptyAllTransactions = transactions => {
        return transactions.every(
            transaction => transaction.value === 0 && transaction.totalTransaction === 0,
        );
    };

    const handleStatusCountCode = (title, code) => {
        navigate('/transactions', {
            state: {
                transactionType: title,
                transactionStatus: code,
                dateFilter: {
                    date: ['all'],
                    startDate: 'all',
                    endDate: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
                },
                clientId: clientData?.clientId,
            },
        });
    };

    return (
        <>
            <div className="lg:pt-8 bg-white overflow-hidden">
                <div className="details-top">
                    <p className="details-top-title">
                        <span className="lg:block hidden text-lg">Transactions summary</span>
                        <span className="sm:block lg:hidden text-base">Summary</span>
                    </p>
                    <div className="font-medium text-sm">
                        <ThemeButton
                            type="primary"
                            icon={<PlusOutlined />}
                            style="mr-3"
                            autoWidth={true}
                            dataE2e="create-new-transaction"
                            text={windowWidth >= 1024 ? 'Create new transaction' : 'Add new'}
                            action={() => setTransactionModal(true)}
                        />
                        <ThemeButton
                            text={windowWidth >= 1024 ? 'See all transactions' : 'See all'}
                            dataE2e="see-all-transactions"
                            autoWidth={true}
                            action={() =>
                                navigate('/transactions', {
                                    state: { clientId: clientData?.clientId },
                                })
                            }
                        />
                    </div>
                </div>
                {/* <Divider className="lg:block hidden my-6" /> */}
                {/* <div className="col-span-2 account-details">
                    <Tabs
                        defaultActiveKey="All"
                        items={items}
                        className="navlist-color-white lg:block"
                        onChange={key => {
                            setSelectedTab(key);
                        }}
                    />
                </div> */}
                <div className="mt-6 border rounded-lg border-[#E4E4E7]">
                    <div className="flex items-center h-[36px] z-50 cursor-pointer border-b mt-6 p-5">
                        {items.map(item => (
                            <div
                                key={item.key}
                                className={`flex justify-center items-center p-2 text-[15px] font-medium text-[#70707B] ${
                                    selectedTab.includes(item.key)
                                        ? 'border-b-2 border-[#18181B] !text-[#18181B]'
                                        : ''
                                }`}
                                onClick={() => setSelectedTab(item.key)}>
                                {item.label}
                            </div>
                        ))}
                    </div>

                    {loading || !transactionDetails || balanceLoading ? (
                        <div className="flex flex-col gap-5">
                            <Skeleton />
                            <Skeleton />
                        </div>
                    ) : (
                        <div className=" border-[#E4E4E7]">
                            {selectedTab === 'All' ? (
                                <div className="p-5 lg:p-0 pb-0">
                                    <div className="flex justify-between items-center p-3 lg:p-6 border-b border-[#E4E4E7]">
                                        <p className="text-base font-semibold">All Transaction:</p>
                                        <p
                                            className="text-base font-normal text-darkGray"
                                            data-e2e={`${totalFiatBalance >= 0 && `${formatAmount(totalFiatBalance)}`}`}>
                                            {/* ${' '} */}
                                            {/* {formatAmount(
                                            transactionDetails.allTransactions.reduce(
                                                (accumulator, transaction) => {
                                                    if (
                                                        transaction.label === 'Deposit' ||
                                                        transaction.label === 'FX'
                                                    ) {
                                                        return accumulator + transaction.value;
                                                    } else if (
                                                        transaction.label === 'Transfer' ||
                                                        transaction.label === 'Withdrawal' ||
                                                        transaction.label === 'Payment Out'
                                                    ) {
                                                        return accumulator - transaction.value;
                                                    } else {
                                                        return accumulator;
                                                    }
                                                },
                                                0,
                                            ) || 0,
                                        )}{' '} */}
                                            {totalFiatBalance >= 0 &&
                                                `$ ${formatAmount(totalFiatBalance) || 0}`}
                                            <span
                                                className={`text-[#1570EF] font-medium ml-1 hover:underline ${
                                                    !isEmptyAllTransactions(
                                                        transactionDetails.allTransactions,
                                                    )
                                                        ? 'cursor-pointer'
                                                        : 'cursor-default'
                                                }`}
                                                onClick={() =>
                                                    !isEmptyAllTransactions(
                                                        transactionDetails.allTransactions,
                                                    ) &&
                                                    handleStatusCountCode(
                                                        transactionTypes
                                                            .slice(0, 5)
                                                            .map(transaction => transaction.value),
                                                        'totalTransactions',
                                                    )
                                                }>
                                                {!isEmptyAllTransactions(
                                                    transactionDetails.allTransactions,
                                                )
                                                    ? formatAmount(
                                                          transactionDetails?.allTransactions.reduce(
                                                              (accumulator, transaction) => {
                                                                  return (
                                                                      accumulator +
                                                                      transaction.totalTransaction
                                                                  );
                                                              },
                                                              0,
                                                          ),
                                                      )
                                                    : 'N/A'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="lg:px-6">
                                        {transactionDetails.allTransactions.map((item, index) => (
                                            <div
                                                className="flex justify-between items-center border-b border-[#E4E4E7] p-3 lg:px-0"
                                                key={index}>
                                                <p className="text-sm font-normal text-darkGray">
                                                    {item.type}:
                                                </p>
                                                <p
                                                    className="text-sm font-normal text-darkGray"
                                                    data-e2e-type={item.type}
                                                    data-e2e={`${formatAmount(item.value || 0)}`}>
                                                    $ {formatAmount(item.value || 0)} -
                                                    <span
                                                        className={`text-[#1570EF] font-medium ml-1 hover:underline ${
                                                            item.totalTransaction
                                                                ? 'cursor-pointer'
                                                                : 'cursor-default'
                                                        }`}
                                                        onClick={() =>
                                                            item.totalTransaction &&
                                                            handleStatusCountCode(
                                                                [item.label],
                                                                'totalTransactions',
                                                            )
                                                        }>
                                                        {item.totalTransaction || 'N/A'}
                                                    </span>
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="p-5 lg:p-0 pb-0">
                                        <div className="flex justify-between items-center p-3 lg:p-6 border-b border-[#E4E4E7]">
                                            <p className="text-base font-semibold">
                                                All {selectedTab}s:
                                            </p>
                                            <p className="text-base font-normal text-darkGray">
                                                ${' '}
                                                {formatAmount(
                                                    calculateTotals(
                                                        transactionDetails.typeTransactions[
                                                            selectedTab
                                                        ],
                                                    ).totalUSDAmount || 0,
                                                )}{' '}
                                                -{' '}
                                                <span
                                                    className={`text-[#1570EF] font-medium ml-1 hover:underline ${
                                                        calculateTotals(
                                                            transactionDetails.typeTransactions[
                                                                selectedTab
                                                            ],
                                                        ).totalCount
                                                            ? 'cursor-pointer'
                                                            : 'cursor-default'
                                                    }`}
                                                    onClick={() =>
                                                        calculateTotals(
                                                            transactionDetails.typeTransactions[
                                                                selectedTab
                                                            ],
                                                        ).totalCount &&
                                                        handleStatusCountCode(
                                                            [selectedTab],
                                                            'totalTransactions',
                                                        )
                                                    }>
                                                    {calculateTotals(
                                                        transactionDetails.typeTransactions[
                                                            selectedTab
                                                        ],
                                                    ).totalCount || 'N/A'}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="lg:px-6">
                                            {(selectedTab === 'Deposit' ||
                                            selectedTab === 'FX' ||
                                            selectedTab === 'Transfer'
                                                ? transactionStatus.filter(
                                                      status => status.value !== 'rejected',
                                                  )
                                                : transactionStatus
                                            ).map((status, key) => (
                                                <div
                                                    className="flex justify-between items-center border-b border-[#E4E4E7] p-3 lg:px-0"
                                                    key={key}>
                                                    <p className="text-sm font-normal text-darkGray">
                                                        {status.label}:
                                                    </p>
                                                    <p className="text-sm font-normal text-darkGray">
                                                        ${' '}
                                                        {formatAmount(
                                                            transactionDetails.typeTransactions[
                                                                selectedTab
                                                            ]?.[status.value]?.totalUSDAmount || 0,
                                                        )}{' '}
                                                        -{' '}
                                                        <span
                                                            className={`text-[#1570EF] font-medium ml-1 hover:underline ${
                                                                transactionDetails.typeTransactions[
                                                                    selectedTab
                                                                ]?.[status.value]?.count
                                                                    ? 'cursor-pointer'
                                                                    : 'cursor-default'
                                                            }`}
                                                            onClick={() =>
                                                                transactionDetails.typeTransactions[
                                                                    selectedTab
                                                                ]?.[status.value]?.count &&
                                                                handleStatusCountCode(
                                                                    [selectedTab],
                                                                    status.value,
                                                                )
                                                            }>
                                                            {transactionDetails.typeTransactions[
                                                                selectedTab
                                                            ]?.[status.value]?.count || 'N/A'}
                                                        </span>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <CommonModal
                    title="Create New Transaction"
                    open={transactionModal}
                    handleClose={handleClose}
                    centered
                    footerText={false}>
                    <Form layout="horizontal" className="mt-6 modal-form">
                        <Form.Item label="Account name" name="name" className="mt-6">
                            <div className="w-full sm:w-[320px] ml-auto">
                                <Input
                                    placeholder="Enter Account Name"
                                    className="w-full rounded-3xl p-2 float-end read-only:opacity-50"
                                    value={clientData?.name}
                                    readOnly
                                />
                            </div>
                        </Form.Item>
                        <Form.Item label="Account ID" name="clientId">
                            <div className="w-full sm:w-[320px] ml-auto">
                                <Input
                                    placeholder="Enter Email"
                                    data-e2e="enter-email"
                                    className="w-full rounded-3xl p-2 float-end read-only:opacity-50"
                                    value={clientData?.clientId}
                                    readOnly
                                />
                            </div>
                        </Form.Item>
                        <div className="transaction-actions transaction-actions-fiat">
                            {navbarItems.map((item, index) => (
                                <button
                                    className={`transaction-actions-btn`}
                                    key={index}
                                    onClick={() => openModalAction(item.type)}>
                                    <div className="transaction-actions-icon">
                                        <img
                                            data-e2e={item.type}
                                            src={`${imageBaseUrl}${item.icon}`}
                                            alt=""
                                        />
                                    </div>
                                    <p className="transaction-actions-title">{item.type}</p>
                                </button>
                            ))}
                        </div>
                    </Form>
                </CommonModal>
                <WithdrawalAndTPPFiatModal
                    open={isModalOpen && openModal === 'Withdrawal'}
                    confirmTitle={'Withdrawal Confirmation'}
                    initialTitle={'Withdrawal'}
                    transactionType={'Withdrawal'}
                    successProcess={'withdrawn'}
                    handleClose={onModalClose}
                    balancesData={balancesData}
                    selectedCurrency={selectedCurrency}
                    setSelectedCurrency={setSelectedCurrency}
                    beneficiaryList={beneficiaryList}
                    getBeneficiaryList={getBeneficiaryList}
                    onWithdrawalSubmit={body => createTransaction(body)}
                />
                <WithdrawalAndTPPFiatModal
                    open={isModalOpen && openModal === 'Third-party payment'}
                    confirmTitle={'Third-party Payment Confirmation'}
                    initialTitle={'Third-party Payment'}
                    transactionType={'Payment Out'}
                    successProcess={'sent'}
                    handleClose={onModalClose}
                    balancesData={balancesData}
                    selectedCurrency={selectedCurrency}
                    setSelectedCurrency={setSelectedCurrency}
                    beneficiaryList={beneficiaryList}
                    getBeneficiaryList={getBeneficiaryList}
                    onWithdrawalSubmit={body => createTransaction(body)}
                />
                <TransferModal
                    transferModal={isModalOpen && openModal === 'Transfer'}
                    client={id}
                    handleClose={onModalClose}
                    balancesData={balancesData}
                    onSubmit={body => createTransaction(body)}
                />
                <FXFiatModal
                    client={id}
                    open={isModalOpen && openModal === 'FX'}
                    handleClose={onModalClose}
                    onSubmit={body => createTransaction(body)}
                />
                <DepositFiatModal
                    client={id}
                    open={isModalOpen && openModal === 'Deposit'}
                    handleClose={onModalClose}
                    onSubmit={body => createTransaction(body)}
                />
                <MerchantTransfer
                    transferModal={isModalOpen && openModal === 'Merchant'}
                    client={id}
                    handleClose={onModalClose}
                    balancesData={balancesData}
                    onSubmit={body => createTransaction(body)}
                />
            </div>
        </>
    );
};

export default TransactionTab;
